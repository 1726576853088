import React from 'react'
import { graphql } from 'gatsby';

// Components
import Layout from '../components/layout/Layout'
import Header from '../components/layout/Header'
import Card from '../components/general/Card';
import TeamList from '../components/team/TeamList';

const team = ({ data, location}) => {

  const teamMembers = data.contentfulOrderTeamMembers;

  const { topLevel, belowLevel } = teamMembers;


  const navTeamMember = (currTeamMember, direction, updateActiveProfile) => {

    // topLevel & belowLevel are both arrays of team members
    // They had to be split according to the designs on the page and in order to allow people to drag and
    // drop the order of the team members in CMS
    // Therefore there is a bit of logic required to handle the boundary transition from one array to
    // to another when the user navigates through different profiles

    // First find which array the current teamMember is in
    const currTeamMemberArray = topLevel.indexOf(currTeamMember) !== -1 ? topLevel : belowLevel

    // Get the current index position of the team member
    const currIndexPosition = currTeamMemberArray.indexOf(currTeamMember)


    // The new index to transition to, calculated by the direction and the modifying the current index
    const newIndexPosition = direction === "forwards" ? (currIndexPosition + 1) : (currIndexPosition - 1);


    // Need to determine if the index is on the array border
    // Show the other array that connecting point



    // i.e. person 1 (index 0) -> navigating backwards goes into the belowLevel array but returns belowLevel[belowLevel.length-1]
    if (newIndexPosition < 0 ) {
       // Assign the value of the other array (if required)
        const otherTeamMemberArray = currTeamMemberArray === topLevel ? belowLevel : topLevel

        return updateActiveProfile(otherTeamMemberArray[otherTeamMemberArray.length-1])
    }

    // i.e. person 8 (index 7) -> navigating forwards goes into the belowLevel array and return belowLevel[0]
    if (newIndexPosition > (currTeamMemberArray.length - 1)) {
      // Assign the value of the other array (if required)
      const otherTeamMemberArray = currTeamMemberArray === topLevel ? belowLevel : topLevel
      return updateActiveProfile(otherTeamMemberArray[0])
    }

    // If its not on an array boundary simply return the same array with the index modified by the direction
    return updateActiveProfile(currTeamMemberArray[newIndexPosition])
}


  return (
    <Layout
      gutters={false}
      activePage="/team"
      container={false}
      location={location}
    >
      <Header
        title="Meet the team | London's office space experts | Kontor"
        description="Meet the faces behind Kontor. Get to know our team of experts that are helping amazing companies & offices find eachother across London & the globe."
      />



      {/* Header */}
      <div className="lg:mb-16 max-w-7xl mx-auto px-0 md:px-8">
        <div className="lg:mt-48 mt-32 md:mb-12">
          <h2 className=" text-center text-4xl px-8 md:px-0">"Look Mum, I'm on the internet!"</h2>
          <h3 className=" text-center mt-6 text-2xl px-8 md:px-0">...meet the Kontor Crew.</h3>
        </div>

        {/* Team Cards */}
        <div className="my-12 lg:mt-28 lg:mb-0 ">
          <TeamList navTeamMember={navTeamMember} teamMembers={[...topLevel, ...belowLevel]} />
        </div>
      </div>

      <div data-aos="fade-up" data-aos-duration="1000">
        <Card type="primary" className="bg-secondary flex max-w-7xl mx-auto mt-8 md:py-8 md:px-16">
          <div className="md:w-1/2 flex">
            <h2 className="lg:border-b-2 leading-normal mb-6 lg:mb-0 lg:border-primary inline-block px-0 text-3xl self-center mx-auto md:px-12 sm:text-center lg:px-0 lg:text-right">We are at your service!</h2>
          </div>
          <div className="md:w-1/2 flex flex-col justify-around">
            <p className="mb-4">
              Empty phrase? Heck no. By your side at viewings, cheering you on, always at the end of a call or email. But who will you find yourself chatting to?
            </p>
            <p className="mb-0">
              There are 17 of us spread across London and New York. Let’s not be strangers...
            </p>
          </div>
        </Card>
      </div>

      {/* CTA */}

      <div className="text-center mt-32 mb-56"
        data-aos="fade-up" data-aos-duration="1000"
      >
        <h2 className=" mb-6">Fancy joining us?</h2>
        <p>Email <a className="underline cursor-pointer" href="mailto:work@kontor.com" >work@kontor.com</a> with a few words about why we’d be so great together.
          </p>
      </div>

    </Layout>
  )
}



// was 064e7592-c4af-56bd-8d67-3b8c76792c78
export const teamQuery = graphql`
query TeamQuery {
    contentfulOrderTeamMembers(id: {eq: "cda7729c-b037-555f-981f-fdfde9816ca1"}) {
      id
      topLevel {
        id
        name
        role
        interview {
          json
        }
        personalHeadline {
          personalHeadline
        }
        photo {
          title
          file {
            url
          }
          fixed(height: 190, width: 147) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
          description
        }
        teamTestimonials {
          id
          companyName
          name
          companyLogo {
            file {
              url
            }
            description
            title
          }
          profilePhoto {
            file {
              url
            }
            title
            description
            fixed(width: 48, height: 48) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
          review {
            review
          }
          logoIsWhite
        }
      }
      belowLevel {
        name
        id
        interview {
          json
        }
        role
        personalHeadline {
          personalHeadline
        }
        teamTestimonials {
          companyLogo {
            file {
              url
            }
            title
            description
          }
          companyName
          name
          logoIsWhite
          review {
            review
          }
          profilePhoto {
            title
            description
            file {
              url
            }
            fixed(width: 48, height: 48) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
        photo {
          title
          description
          file {
            url
          }
          fixed(height: 190, width: 147) {
            ...GatsbyContentfulFixed_withWebp
          }
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
  
`

export default team
